import React, { useEffect } from "react";

import { notification, Spin } from "antd";
import { ErrorMessage, Field, Form, Formik } from "formik";
import { EyeIcon, EyeOff } from "lucide-react";
import { useNavigate } from "react-router-dom";

import Cookies from "js-cookie";
import * as Yup from "yup";

import packageJason from "../../../package.json";
import { AccessControl as AccessControlClass } from "../../services/apis/accessControlServices";
import { NotificationType } from "../../types/commonTypes";

import dayjs from "dayjs";
import { useSelector } from "react-redux";
import { RootState } from "../../store/store";
import styles from "./newAccess.module.scss";

// Esquema de validação utilizando Yup
const LoginSchema = Yup.object().shape({
  email: Yup.string()
    .email("Email inválido")
    .required("Informe um endereço de e-mail"),
  password: Yup.string()
    .min(6, "A senha deve ter pelo menos 6 caracteres")
    .required("Informe a senha."),
});

export function NewAccessControl() {
  const version = packageJason.version;
  const navigate = useNavigate();
  const styleBySubDomain = useSelector((state: RootState) => state.styleSlice);
  const [api, contextHolder] = notification.useNotification();
  const [showPassword, setShowPassword] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [emailUser, setEmailUser] = React.useState("");
  const [remember, setRemember] = React.useState(false);
  const [showLogin, setShowLogin] = React.useState(false);

  useEffect(() => {
    const email = Cookies.get("remenberEmail");
    if (email) {
      setEmailUser(email);
      setRemember(true);
      setShowLogin(true);
    } else {
      setShowLogin(true);
    }
  }, []);

  const openNotificationWithIcon = (
    type: NotificationType,
    title: string,
    description: string
  ) => {
    api[type]({
      message: title,
      description: description,
    });
  };

  const handleSignIn = async (values: {
    email: string;
    password: string;
    staySignedIn?: boolean;
  }) => {
    setLoading(true);
    try {
      const authServices = new AccessControlClass();

      if (values.staySignedIn) {
        Cookies.set("remenberEmail", values.email, {
          expires: 182,
          sameSite: "strict",
        });
      } else {
        Cookies.remove("remenberEmail");
      }

      const respLogin = await authServices.signIn({
        email: values.email,
        password: values.password,
      });

      navigate("/");
      setLoading(false);
    } catch (error: any) {
      openNotificationWithIcon(
        "error",
        "Erro ao efetuar login!",
        error.message
      );
      setLoading(false);
    }
  };

  return (
    <div
      className={styles.loginContainer}
      style={{ backgroundColor: `${styleBySubDomain.backgroundColor}` }}
    >
      <div className={styles.imageAndForm}>
        {contextHolder}
        <img src={styleBySubDomain.logoImage} alt="Logo centro de Inovação" />
        <div style={{ margin: "24px 0" }}>
          <h1>Seja bem-vindo!</h1>
        </div>
        <div className={styles.loginForm}>
          <p>Faça seu login</p>

          {showLogin && (
            <Formik
              initialValues={{
                email: emailUser,
                password: "",
                staySignedIn: remember,
              }}
              validationSchema={LoginSchema}
              onSubmit={(values, { setSubmitting }) => {
                handleSignIn(values);
                setSubmitting(false);
                // Aqui você adicionaria a lógica de autenticação
              }}
            >
              {({ isSubmitting }) => (
                <Form style={{ gap: 24 }}>
                  {/* <label htmlFor="email">Email</label> */}
                  <Field type="email" name="email" placeholder="E-mail" />
                  <ErrorMessage
                    name="email"
                    component="div"
                    className={styles.error}
                  />

                  {/*  <label htmlFor="password">Senha</label> */}
                  <Field type="password" name="password" placeholder="Senha">
                    {({ field }: any) => (
                      <div className={styles.passwordField}>
                        <input
                          placeholder="Senha"
                          type={!showPassword ? "password" : "text"}
                          {...field}
                        />
                        {!showPassword ? (
                          <EyeOff
                            onClick={() => setShowPassword(!showPassword)}
                            size={24}
                          />
                        ) : (
                          <EyeIcon
                            onClick={() => setShowPassword(!showPassword)}
                            size={24}
                          />
                        )}
                      </div>
                    )}
                  </Field>
                  <ErrorMessage
                    name="password"
                    component="div"
                    className={styles.error}
                  />

                  <div className={styles.checkboxContainer}>
                    <Field
                      type="checkbox"
                      name="staySignedIn"
                      id="staySignedIn"
                    />
                    <label htmlFor="staySignedIn">Continuar conectado</label>
                  </div>

                  <button type="submit" disabled={isSubmitting}>
                    Entrar
                  </button>
                </Form>
              )}
            </Formik>
          )}
          <Spin
            tip="Loading"
            size="large"
            className={styles.spinLoading}
            spinning={loading}
          >
            <div className={styles.contentSpin} />
          </Spin>
        </div>
        <div className={styles.links}>
          <p>
            Ainda não tem uma conta?{" "}
            <span>
              <a href="/registration/innovation-center">Criar conta</a>
            </span>
          </p>

          <a href="/">Esqueci minha senha</a>
        </div>
      </div>
      <div className={styles.boxVersion}>
        <p>
          IntellectHub | {dayjs().format("YYYY")} • Todos os direitos reservados
          | Versão {version} - HML
        </p>
      </div>
      <div
        className={styles.loginImgContent}
        style={{ backgroundImage: `${styleBySubDomain.backgroundImage}` }}
      ></div>
    </div>
  );
}
