import { Badge } from "antd";
import { Bell, LogOut, Menu, UserCog } from "lucide-react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { AccessControl } from "../../../../services/apis/accessControlServices";
import { toggleDrawer } from "../../../../store/layoutSlice";
import { RootState } from "../../../../store/store";
import styles from "./styleNavbar.module.scss";

export default function Navbar() {
  const accessControlService = new AccessControl();
  //const location = useLocation();
  //const user = Cookies.get("profile");
  //const userParse = user ? JSON.parse(user) : null;
  const navigate = useNavigate();
  const updates = useSelector(
    (state: RootState) => state.notifications.updates
  );
  const styleBySubDomain = useSelector((state: RootState) => state.styleSlice);
  const dispatch = useDispatch();

  const handleLogout = async () => {
    await accessControlService.signOut();
    window.location.reload();
  };

  //const pathSnippets = location.pathname.split("/").filter((i) => i);

  /* const breadcrumbItems = pathSnippets.map((_, index) => {
    const url = `/${pathSnippets.slice(0, index + 1).join("/")}`;
    return <Breadcrumb.Item key={url}>{url}</Breadcrumb.Item>;
  }); */

  return (
    <div
      className={styles.background}
      style={{ backgroundColor: styleBySubDomain.backGroundColorMenu }}
    >
      <div className={styles.navbar}>
        <div className={styles.menu}>
          <Menu
            onClick={() => {
              dispatch(toggleDrawer());
            }}
            className={styles.icon}
          />
        </div>

        <div className={styles.rightContainer}>
          <Badge count={updates.length} title="Novos chamados abertos">
            <Bell className={styles.icon} onClick={() => navigate("/tikets")} />
          </Badge>
          <UserCog
            className={styles.icon}
            onClick={() => console.log("profile")}
          />
          <LogOut onClick={handleLogout} className={styles.icon} />
        </div>
      </div>
    </div>
  );
}
