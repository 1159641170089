import { Image, Menu, MenuProps } from "antd";
import Cookies from "js-cookie";
import {
  Bolt,
  Briefcase,
  Building2,
  CalendarRange,
  ClipboardMinus,
  DoorOpen,
  Headset,
  HeartHandshake,
  Home,
  PackagePlus,
  Pencil,
  ShieldAlert,
  User,
  Wallet,
} from "lucide-react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { RootState } from "../../../../store/store";

import { useEffect } from "react";
import styles from "./styleSidebar.module.scss";

export default function Sidebar() {
  let location = window.location.pathname;
  const navigate = useNavigate();
  const styleBySubDomain = useSelector((state: RootState) => state.styleSlice);
  const userProfile = Cookies.get("profile");
  const user = Cookies.get("user");
  const userParse = user ? JSON.parse(user) : null;
  const profile = userProfile ? JSON.parse(userProfile) : null;

  type MenuItem = Required<MenuProps>["items"][number];

  function getItem(
    label: React.ReactNode,
    key: React.Key,
    icon?: React.ReactNode,
    children?: MenuItem[],
    type?: "group"
  ): MenuItem {
    return {
      key,
      icon,
      children,
      label,
      type,
    } as MenuItem;
  }

  console.log("userParse", userParse);

  function LabelItemMenu(label: string, icon: React.ReactNode) {
    return (
      <div className={styles.labelItemMenu}>
        {icon}
        <span>{label}</span>
      </div>
    );
  }

  const itensMenu: any = {
    innovation: [
      getItem(LabelItemMenu("Painel", <Home />), "/"),
      getItem(LabelItemMenu("Instituições", <Briefcase />), "sub 1", "", [
        getItem(LabelItemMenu("Empresas", <Building2 />), "/start-up"),
        getItem(LabelItemMenu("Fornecedores", <PackagePlus />), "/suppliers"),
        getItem(
          LabelItemMenu("Patrocinadores", <HeartHandshake />),
          "/sponsors"
        ),
      ]),
      getItem(LabelItemMenu("Chamados", <Headset />), "/tikets"),
      getItem(LabelItemMenu("Eventos", <CalendarRange />), "/events"),
      getItem(LabelItemMenu("Salas", <DoorOpen />), "/rooms"),
      getItem(
        LabelItemMenu("Controle de danos", <ShieldAlert />),
        "/damage_control"
      ),
      getItem(LabelItemMenu("Financeiro", <Wallet />), "/financial"),
      getItem(LabelItemMenu("Relatórios", <ClipboardMinus />), "#"),
      getItem(LabelItemMenu("Configurações", <Bolt />), "sub 2", "", [
        getItem(LabelItemMenu("Colaboradores", <User />), "/config/users"),
        // getItem(LabelItemMenu('Centro de Inovação', <Pencil />), '/config/innovation-center-info'),
      ]),
    ],
    startup: [
      getItem(LabelItemMenu("Painel", <Home />), "/"),
      getItem(LabelItemMenu("Chamados", <Headset />), "/tikets"),
      getItem(LabelItemMenu("Eventos", <CalendarRange />), "/events"),
      getItem(LabelItemMenu("Salas", <DoorOpen />), "/rooms"),
      getItem(LabelItemMenu("Configurações", <Bolt />), "sub 2", "", [
        getItem(LabelItemMenu("Colaboradores", <User />), "/config/users"),
        // getItem(LabelItemMenu('Empresa', <Pencil />), '/config/enterprise'),
      ]),
    ],
    ADM:
      profile[0]?.role_ref === "innovation"
        ? [
            getItem(LabelItemMenu("Painel", <Home />), "/"),
            getItem(LabelItemMenu("Instituições", <Briefcase />), "sub 1", "", [
              getItem(LabelItemMenu("Empresas", <Building2 />), "/start-up"),
              getItem(
                LabelItemMenu("Fornecedores", <PackagePlus />),
                "/suppliers"
              ),
              getItem(
                LabelItemMenu("Patrocinadores", <HeartHandshake />),
                "/sponsors"
              ),
            ]),
            getItem(LabelItemMenu("Chamados", <Headset />), "/tikets"),
            getItem(LabelItemMenu("Eventos", <CalendarRange />), "/events"),
            getItem(LabelItemMenu("Salas", <DoorOpen />), "/rooms"),
            getItem(
              LabelItemMenu("Controle de danos", <ShieldAlert />),
              "/damage_control"
            ),
            getItem(LabelItemMenu("Financeiro", <Wallet />), "/financial"),
            getItem(LabelItemMenu("Relatórios", <ClipboardMinus />), "#"),
            getItem(LabelItemMenu("Configurações", <Bolt />), "sub 2", "", [
              getItem(
                LabelItemMenu("Colaboradores", <User />),
                "/config/users"
              ),
              getItem(
                LabelItemMenu("Centro de Inovação", <Pencil />),
                "/config/innovation-center-info"
              ),
              getItem(
                LabelItemMenu("Empresa", <Pencil />),
                "/config/enterprise"
              ),
            ]),
          ]
        : [
            getItem(LabelItemMenu("Chamados", <Headset />), "/tikets"),
            getItem(LabelItemMenu("Salas", <DoorOpen />), "/rooms"),
            getItem(LabelItemMenu("Configurações", <Bolt />), "sub 2", "", [
              getItem(
                LabelItemMenu("Colaboradores", <User />),
                "/config/users"
              ),
              getItem(
                LabelItemMenu("Empresa", <Pencil />),
                "/config/enterprise"
              ),
            ]),
          ],
    ATENDIMENTO: [
      getItem(LabelItemMenu("Chamados", <Headset />), "/tikets"),
      getItem(LabelItemMenu("Eventos", <CalendarRange />), "/events"),
      getItem(LabelItemMenu("Salas", <DoorOpen />), "/rooms"),
    ],
    EVENTOS: [getItem(LabelItemMenu("Eventos", <CalendarRange />), "/events")],
  };
  const items: MenuProps["items"] = itensMenu[profile[0]?.role];

  //Definir location de acordo com a role do usuário
  const locationByRole = () => {
    if (profile[0]?.role === "innovation") {
      location = "/";
    } else if (profile[0]?.role === "startup") {
      location = "/";
    } else if (profile[0]?.role === "ADM") {
      location = "/";
    } else if (profile[0]?.role === "ATENDIMENTO") {
      location = "/tikets";
      navigate("/tikets");
    } else if (profile[0]?.role === "EVENTOS") {
      location = "/events";
      navigate("/events");
    }
  };

  useEffect(() => {
    locationByRole();
  }, []);

  const onClick: MenuProps["onClick"] = (e) => {
    navigate(e.key as string);
  };

  return (
    <aside
      className={`${styles.sidebar} ${
        styleBySubDomain.backGroundColorMenu === "var(--purple-800)"
          ? styles.ulChange
          : ""
      }`}
      style={{ backgroundColor: `${styleBySubDomain.backGroundColorMenu}` }}
    >
      <div
        className={styles.container}
        style={{ backgroundColor: `${styleBySubDomain.backGroundColorMenu}` }}
      >
        <Image
          src={styleBySubDomain.logoImage}
          alt="Logo"
          preview={false}
          width="100%"
          style={{ marginBottom: "2rem" }}
        />
        <Menu
          onClick={onClick}
          style={{
            width: 256,
            backgroundColor: `${styleBySubDomain.backGroundColorMenu}`,
          }}
          defaultSelectedKeys={[location]}
          defaultOpenKeys={[location]}
          mode="inline"
          theme={"dark"}
          items={items}
        />
      </div>
    </aside>
  );
}
