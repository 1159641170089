import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { BrowserRouter } from "react-router-dom";

import moment from "moment";
import "moment/locale/pt-br";

import { initializeWebSocket } from "./services/webSocket/webSocketInitilize";
import { closeWebSocket } from "./services/webSocket/websocket";

import imageElumeFaxada from "./assets/images/elume_park.png";
import faxadaDefault from "./assets/images/image-default-login.png";
import logoElumeBranca from "./assets/images/logoElumeBranca.png";
import logoDefault from "./assets/images/logoIntellectHub_login.png";
import Router from "./routes/route";

import { ConfigProvider } from "antd";
import { useSelector } from "react-redux";
import { RootState } from "./store/store";
import { setStyle } from "./store/styleSlice";

import ptBR from "antd/locale/pt_BR";

moment.locale("pt-br");

function App() {
  const dispatch = useDispatch();

  const styleBySubDomain = useSelector((state: RootState) => state.styleSlice);

  useEffect(() => {
    initializeWebSocket(dispatch);

    return () => {
      // Clean up WebSocket connection if necessary
      closeWebSocket();
    };
  }, [dispatch]);

  useEffect(() => {
    const subdomain = window.location.hostname.split(".")[0];
    // Defina os estilos baseados no subdomínio
    const styles: any = {
      "elume-stage": {
        backgroundColor: "#6E2DB0",
        backGroundColorMenu: "#6E2DB0",
        backGroundColorSubMenu: "var(--purple-600)",
        backGroundColorSelected: "var(--purple-500)",
        backgroundImage: `url(${imageElumeFaxada})`,
        logoImage: logoElumeBranca,
      },
      elume: {
        backgroundColor: "#6E2DB0",
        backGroundColorMenu: "#6E2DB0",
        backGroundColorSubMenu: "var(--purple-600)",
        backGroundColorSelected: "var(--purple-500)",
        backgroundImage: `url(${imageElumeFaxada})`,
        logoImage: logoElumeBranca,
      },
      stage: {
        backgroundColor: "#000058",
        backGroundColorMenu: "#010c2a",
        backGroundColorSelected: "var(--blue-600)",
        backgroundImage: `url(${faxadaDefault})`,
        logoImage: logoDefault,
      },
      default: {
        backgroundColor: "#000058",
        backGroundColorMenu: "#010c2a",
        backGroundColorSelected: "var(--blue-600)",
        backgroundImage: `url(${faxadaDefault})`,
        logoImage: logoDefault,
      },
    };

    const currentStyle = styles[subdomain] ?? styles["default"];
    dispatch(setStyle(currentStyle));
  }, [dispatch]);

  return (
    <BrowserRouter>
      <ConfigProvider
        locale={ptBR}
        theme={{
          components: {
            Menu: {
              darkItemSelectedBg: styleBySubDomain.backGroundColorSelected,
              darkSubMenuItemBg: styleBySubDomain.backGroundColorSubMenu ?? "",
            },
            Pagination: {
              colorPrimaryActive: styleBySubDomain.backgroundColor,
              colorPrimary: styleBySubDomain.backgroundColor,
              colorPrimaryHover: styleBySubDomain.backGroundColorSelected,
            },
            Progress: {
              defaultColor: styleBySubDomain.backgroundColor,
            },
            Button: {
              colorPrimary: styleBySubDomain.backgroundColor,
              colorPrimaryHover: styleBySubDomain.backGroundColorSelected,
              colorPrimaryActive: styleBySubDomain.backGroundColorSelected,
            },
            Input: {
              colorPrimary: styleBySubDomain.backgroundColor,
              activeShadow: styleBySubDomain.backGroundColorSelected,
              colorPrimaryHover: styleBySubDomain.backgroundColor,
              colorPrimaryActive: styleBySubDomain.backgroundColor,
            },
            Cascader: {
              colorBorder: styleBySubDomain.backgroundColor,
              colorPrimary: styleBySubDomain.backgroundColor,
              colorPrimaryHover: styleBySubDomain.backGroundColorSelected,
            },
            Select: {
              colorPrimaryHover: styleBySubDomain.backgroundColor,
              colorPrimaryActive: styleBySubDomain.backgroundColor,
              colorPrimary: styleBySubDomain.backgroundColor,
            },
            DatePicker: {
              colorPrimary: styleBySubDomain.backgroundColor,
              colorPrimaryHover: styleBySubDomain.backGroundColorSelected,
              colorPrimaryActive: styleBySubDomain.backgroundColor,
            },
            Tabs: {
              colorPrimary: styleBySubDomain.backgroundColor,
              colorPrimaryHover: styleBySubDomain.backGroundColorSelected,
              colorPrimaryActive: styleBySubDomain.backGroundColorSelected,
            },
            Checkbox: {
              colorPrimary: styleBySubDomain.backgroundColor,
              colorPrimaryHover: styleBySubDomain.backGroundColorSelected,
              colorPrimaryActive: styleBySubDomain.backGroundColorSelected,
            },
            Switch: {
              colorPrimary: styleBySubDomain.backgroundColor,
              colorPrimaryHover: styleBySubDomain.backGroundColorSelected,
              colorPrimaryActive: styleBySubDomain.backGroundColorSelected,
            },
          },
        }}
      >
        <Router />
      </ConfigProvider>
    </BrowserRouter>
  );
}

export default App;
