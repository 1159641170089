import { Button, Card, List, Progress, Statistic, Table } from "antd";
import Cookies from "js-cookie";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import styles from "./homeStyles.module.scss";

export function Home() {
  const navigate = useNavigate();
  const validRoles = ["ATENDIMENTO"];
  const profile = Cookies.get("profile");
  const profileParsed = profile ? JSON.parse(profile) : null;

  useEffect(() => {
    if (validRoles.includes(profileParsed[0].role)) {
      navigate("/tikets");
    }
  }, []);
  const dataSource = [{ key: "1", name: "Chamado 1", status: "Aberto" }];

  const columns = [{ title: "Nome", dataIndex: "name", key: "name" }];

  return (
    <div
      style={{
        gap: "24px",
      }}
    >
      <div
        style={{
          gap: "24px",
          display: "flex",
          width: "100%",
        }}
      >
        <div className={styles.cardsContainer}>
          <Card className={styles.cards}>
            <Statistic title="Chamados Abertos" value={0} />
          </Card>

          <Card className={styles.cards}>
            <Statistic title="Eventos Planejados" value={0} />
          </Card>

          <Card className={styles.cards}>
            <Statistic title="Salas Reservadas" value={0} />
          </Card>
        </div>
      </div>
      <div
        style={{ marginTop: 20, width: "100%", gap: "24px", display: "flex" }}
      >
        <div className={styles.cardsContainer}>
          <Card title="Últimas Atividades" className={styles.cardActivities}>
            <List
              dataSource={dataSource}
              renderItem={(item) => (
                <List.Item>
                  <List.Item.Meta
                    title={item.name}
                    description={`Status: ${item.status}`}
                  />
                </List.Item>
              )}
            />
          </Card>

          <Card title="Chamados Recentes" className={styles.cardActivities}>
            <Table dataSource={dataSource} columns={columns} />
          </Card>
        </div>
      </div>
      <div style={{ marginTop: 20 }}>
        <Card title="Resumo Financeiro">
          <Progress percent={0} status="active" />
          <Button type="primary" style={{ marginTop: 20 }}>
            Ver Detalhes
          </Button>
        </Card>
      </div>
    </div>
  );
}
